import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import marked from "marked"

class BlogTemplate extends React.Component {
  render() {
    const { post, allSanityPerson } = this.props.data
    console.log("BlogTemplate -> render -> post", post)
    const { previous, next } = this.props.pageContext
    const author = post.authors.length ? post.authors[0].person : ""

    console.log("post", author)

    // console.log(previous, next)
    return (
      <Layout location={this.props.location}>
        <SEO title={post.title} description={post.excerpt} />
        {/* <div className="page-headline">
          <div className="container">
            <div className="section-heading text-center">
              <h6 className="font-weight-bold text-uppercase text-white-50 flair">
                Blog
              </h6>
              <h1>
                <strong>{post.title}</strong>
                <br />
              </h1>
              <span className="text-white-50 small">
                <i className="fa fa-calendar-o pr-1" />
                {post.publishedAt}
              </span>
            </div>
          </div>
        </div> */}
        <div className="page-content blog-innerpage">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="blog-title text-center mb-5">
                  <h1 className="section-title text-dark text-shadow">
                    <strong>{post.title}</strong>
                    <br />
                  </h1>
                  <span className="text-dark">By: {author.name}</span>
                  <br />
                  <span className="text-dark small">
                    <i className="fa fa-calendar-o pr-1" />
                    {post.publishedAt}
                    {/* <p>
                      {post.authors.person
                        ? post.authors.person
                        : post.authors.person}{" "}
                    </p> */}
                  </span>
                </div>
                <div className="pb-3">
                  <GatsbyImage
                    image={
                      post.mainImage ? post.mainImage.asset.gatsbyImageData : ""
                    }
                    className="img-fluid"
                    layout="constrained"
                    style={{
                      height: "auto",
                      width: "auto",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "20px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: marked(post.body || ""),
                  }}
                />
              </div>
            </div>
            <div className="blog-nav">
              <div className="row">
                <div className="col-md-6 text-md-left">
                  {previous ? (
                    <div>
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        Previous
                      </span>
                      <Link to={`/blog/${previous.slug.current}`}>
                        {previous.title}
                      </Link>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 text-md-right">
                  {next ? (
                    <div>
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        next
                      </span>
                      <Link to={`/blog/${next.slug.current}`}>
                        {next.title}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      authors {
        person {
          name
        }
      }
      mainImage {
        asset {
          gatsbyImageData(
            width: 885
            fit: FILL
            placeholder: BLURRED
            sizes: ""
            layout: CONSTRAINED
          )
        }
      }
    }
    allSanityPerson {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
